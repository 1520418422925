import React, { useCallback, useMemo, useState } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  ALL_NUMBER_OPERATORS,
  CONTAINS_STRING_OPERATOR,
  createAutocompleteOperator,
  createNotEqualsAutocompleteOperator,
  NOT_CONTAINS_STRING_OPERATOR,
  NOT_EQUAL_SELECT_OPERATOR,
  SELECT_OPERATOR,
} from 'constants/table-filters'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import {
  useAssignBotsCardMutation,
  useGetBotsCardTypesAndAccountNamesQuery,
  useGetBotsQuery,
} from 'services/bots/api'
import { BOT_STATUSES, BotStatus } from 'services/purchasing/types'

import { BotsTableToolbar } from 'components/bots/bots/BotsTableToolbar/BotsTableToolbar'
import { AssignCardModal } from 'components/common/AssignCardModal'
import CardInfoPopup from 'components/common/CardInfoPopup'
import { CopyToClipboard } from 'components/common/CopyToClipboard/index'
import { DetailsModal } from 'components/common/DetailsModal'
import CustomDataGrid from 'components/table/DataGrid'

export type BotsTabActions =
  | 'CREATE_BOT'
  | 'CREATE_BOTS'
  | 'EDIT'
  | 'DELETE'
  | 'EDIT_SINGLE'
  | 'DELETE_SINGLE'
  | 'INFO'
  | 'ASSIGN_CARD'
  | 'CARD_INFO'
  | ''

export const BotsTab = () => {
  const [isHighlight, setIsHighlight] = useState<boolean>(false)
  const handleCellHighlight = (field: string) => {
    console.log(field)
    if (['password', 'login'].includes(field)) {
      setIsHighlight(true)
    }
  }
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        type: 'Number',
        flex: 1,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'archivedStatus',
        headerName: 'Status',
        headerAlign: 'center',
        align: 'center',
        flex: 2,
        type: 'singleSelect',
        valueOptions: BOT_STATUSES,
        valueFormatter: ({ value }) => BotStatus[value as keyof typeof BotStatus],
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
      },
      {
        field: 'account.email',
        headerName: 'Bot User',
        type: 'String',
        flex: 4,
        valueGetter: ({ row }) => row?.login,
        filterOperators: [
          createAutocompleteOperator('/api/purchasing/bots/distinct', 'account.email'),
          createNotEqualsAutocompleteOperator('/api/purchasing/bots/distinct', 'account.email'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
        renderCell: ({ row }) => (
          <CopyToClipboard
            value={row?.login}
            field="login"
            handleCellHighlight={handleCellHighlight}
          />
        ),
      },
      {
        field: 'passwordHp',
        headerName: 'Bot password',
        type: 'string',
        flex: 2,
        filterable: false,
        renderCell: ({ row }) => (
          <CopyToClipboard
            value={row?.password}
            field="password"
            handleCellHighlight={handleCellHighlight}
          />
        ),
      },
      {
        field: 'ticket_system.name',
        headerName: 'Bot Source',
        flex: 3,
        type: 'String',
        valueGetter: ({ row }) => row?.ticketSystem?.name,
        filterOperators: [
          createAutocompleteOperator('/api/purchasing/bots/distinct', 'ticket_system.name'),
          createNotEqualsAutocompleteOperator(
            '/api/purchasing/bots/distinct',
            'ticket_system.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'details',
        headerName: 'Details',
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => (
          <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              handleDetailsClick(row)
            }}
          >
            Show Details
          </Button>
        ),
      },
      {
        field: 'card',
        headerName: 'Credit Cards',
        type: 'string',
        flex: 2,
        sortable: false,
        filterable: false,
        renderCell: ({ row, value }) => {
          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                minWidth: '100%',
              }}
            >
              {value ? (
                <Button
                  size="small"
                  variant="text"
                  onClick={(e) => {
                    e.stopPropagation()
                    setSingleRow([row.id])
                    setModal('CARD_INFO')
                  }}
                >
                  <Typography
                    sx={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {value.num}
                  </Typography>
                </Button>
              ) : (
                <Typography
                  fontSize="14px"
                  pl={1}
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  Not assigned
                </Typography>
              )}
              <IconButton
                sx={{ alignSelf: 'center' }}
                aria-label="copy"
                color="primary"
                size="small"
                onClick={(e) => {
                  e.stopPropagation()
                  setModal('ASSIGN_CARD')
                  setSingleRow([row.id])
                }}
              >
                <EditIcon sx={{ cursor: 'pointer' }} />
              </IconButton>
            </Box>
          )
        },
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('EDIT_SINGLE')
                setSingleRow(params.row)
              }}
              label="Edit Bot"
              showInMenu
            />,
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('DELETE_SINGLE')
                setSingleRow(params.row)
              }}
              label="Delete Bot"
              showInMenu
            />,
          ]
        },
      },
    ],
    []
  )
  const [assignCard, { isLoading: isLoadingAssignCard }] = useAssignBotsCardMutation()
  const { data: dataAssign, isFetching: isFetchingDataAssign } =
    useGetBotsCardTypesAndAccountNamesQuery()
  const { modal, setModal, singleRow, setSingleRow } = useActions<BotsTabActions>()
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetBotsQuery(queryParams)
  const selectedAccount =
    (Array.isArray(singleRow) && data?.data.filter((row) => singleRow?.includes(row.id))?.[0]) ||
    null
  const handleDetailsClick = useCallback((row: any) => {
    setModal('INFO')
    setSingleRow([row])
  }, [])
  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid<BotsTabActions>
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        {...paginationDataGridProps}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        toolbar={BotsTableToolbar}
        rowHeight={70}
        checkboxSelection
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
        onCellClick={() => setIsHighlight(false)}
        sx={{
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: isHighlight ? '' : 'none',
          },
        }}
      />
      {modal === 'INFO' && (
        <DetailsModal
          onClose={() => {
            setModal('')
            setSingleRow([])
          }}
          item={singleRow[0]}
        />
      )}
      {modal === 'ASSIGN_CARD' && selectedAccount && (
        <AssignCardModal
          assignCardHandler={assignCard}
          isLoading={isLoadingAssignCard}
          data={dataAssign}
          isFetching={isFetchingDataAssign}
          onClose={() => setModal('')}
          selectedAccount={selectedAccount}
        />
      )}
      {modal === 'CARD_INFO' && selectedAccount && (
        <CardInfoPopup onClose={() => setModal('')} card={selectedAccount.card!} />
      )}
    </Box>
  )
}
